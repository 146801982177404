.aboutContainer {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 4rem;
}

.clearfix {
	overflow: visible;
}

.clearfix::after {
	clear: both;
}

.aboutImg {
	float: left;
	width: 30%;
	margin-right: 2%;
}
.aboutImg::after {
	content: "";
	clear: both;
}

.aboutParagraphContainer {
	font-size: 1rem;
	flex-basis: auto;
	line-height: 1.5rem;
	align-self: flex-end;
}
.heading {
	margin-top: 8%;
}

.featuredHeadline {
	margin: 2rem 0;
	text-transform: uppercase;
	background: rgb(85, 81, 81);
	padding: 0.5rem 1.5rem;
	color: white;
	text-shadow: 1px 1px 1px black;
	height: 3rem;
	/* border-radius: 100px 100px; */
}

.projectsHeadline {
	margin: 2rem 10rem;
	margin-top: 6rem;
}

.missionContainer {
	background: #eff1e4;
	padding: 0.5rem 3rem;
}

.missionList {
	display: flex;
	justify-content: space-evenly;
	list-style: none;
}

.missionItem {
	display: flex;
	justify-content: center;
}

.missionItemHeadline {
	margin: 1rem;
	padding: 0;
}

.pContainer {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.missionParagraph {
	font-size: 1rem;
	margin: 0;
	padding: 0;
	line-height: 1.5rem;
	color: #647580;
}

.circle {
	border-radius: 50% 50%;
	background: #00273f;
	width: 60px;
	height: 60px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
}

/* center the blockquote in the page */
.blockquoteWrapper {
	display: flex;
	padding: 0 20px;
	margin-bottom: 2rem;
}

/* Blockquote main style */
.blockquote {
	position: relative;
	font-family: "Barlow Condensed", sans-serif;
	max-width: 620px;
	margin: 80px auto;
	align-self: center;
}

/* Blockquote header */
.blockquote h1 {
	font-family: "Abril Fatface", cursive;
	position: relative; /* for pseudos */
	color: #00273f;
	font-size: 2.8rem;
	font-weight: normal;
	line-height: 1;
	margin: 0;
	border: 2px solid #fff;
	border: solid 2px;
	border-radius: 20px;
	padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
	content: "";
	position: absolute;
	border: 2px solid var(--bs-success);
	border-radius: 0 50px 0 0;
	width: 60px;
	height: 60px;
	bottom: -62px;
	left: 50px;
	border-bottom: none;
	border-left: none;
	z-index: 3;
}

.blockquote h1:before {
	content: "";
	position: absolute;
	width: 80px;
	border: 6px solid white;
	bottom: -3px;
	left: 50px;
	z-index: 2;
}

.blockquote h4 {
	position: relative;
	color: #00273f;
	font-size: 1.3rem;
	font-weight: 400;
	line-height: 1.2;
	margin: 0;
	padding-top: 15px;
	z-index: 1;
	margin-left: 150px;
	padding-left: 12px;
}

.blockquote h4:first-letter {
	margin-left: -12px;
}

@media screen and (max-width: 414px) {
	.aboutImg {
		width: 100%;
	}
	.featuredHeadline.projectsHeadline {
		margin: 1rem;
		font-size: 1.15rem;
	}
}

@media screen and (max-width: 375px) {
	.blockquote h1 {
		font-size: 1.8rem;
	}
}

@media all and (min-width: 600px) {
	.blockquote h1 {
		font-size: 3rem;
		line-height: 1.2;
	}
}

@media screen and (max-width: 768px) {
	.missionList {
		flex-direction: column;
	}
}
