.textHeadlineContainer {
  display: flex;
  justify-content: center;
}

.textHeadline {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
  background: rgb(114, 109, 109);
  width: 30rem;
  font-size: 1.5rem;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.circle {
  left: 0;
  background: white;
  border-radius: 50% 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle img {
  opacity: 1;
  width: 70%;
  vertical-align: middle;
}

.packingItems {
  display: flex;
  list-style: none;
}

.packingHeadline {
  display: flex;
  flex-direction: column;
}

.textPacking {
  height: 4rem;
  position: relative;
}

.textPacking h1 {
  position: relative;
  top: 10px;
  padding: 0;
  margin: 0 4rem;
}
