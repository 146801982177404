.container {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.containerSection {
  /* display: flex;
  justify-content: space-around; */
  background: #eff1e4;
  /* height: 30rem; */
  padding: 1rem 0;
  /* align-items: center; */
}

.descriptionTextContainer {
  width: 50%;
  padding: 3rem;
}

.textHeadline {
  position: relative;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
  background: rgb(114, 109, 109);
  width: 30rem;
  font-size: 1.5rem;
  border-radius: 100px;
}

.circle {
  position: absolute;
  left: 0;
  background: #eff1e4;
  border-radius: 50% 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle img {
  opacity: 1;
  width: 70%;
  vertical-align: middle;
}
/* 
.textHeadline h1 {
  padding: 0.5rem 1rem;
  margin-left: 3rem;
} */
.descriptionText {
  letter-spacing: 2px;
  line-height: 1.5;
  font-size: 1.1rem;
}

.descriptionImage {
  width: 30%;
  height: 90%;
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  opacity: 1;
}

/* //////// reverse section //////// */

.reverse {
  flex-direction: row-reverse;
  background: white;
}

.textPacking {
  height: 4rem;
  position: relative;
}

.packingHeadline {
  display: flex;
  flex-direction: column;
}

.cosmeticImg {
  opacity: 1;
  width: 90%;
  height: 90vh;
  /* padding: 5rem; */
  /* height: 60rem; */
  margin: auto;
  display: flex;
  justify-content: center;
}

.videoModal > div {
  width: fit-content;
}
