:root {
	--color-1: #21d4fd;
	--color-2: #b721ff;
	--color-3: #08aeea;
	--color-4: #2af598;
}

.header {
	position: relative;
	height: 80vh;
	display: block;
}

.headerBackground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(19deg, var(--color-1), var(--color-2));
	-webkit-transform-origin: 0px 0px;
	transform-origin: 0px 0px;
	-webkit-transform: skewY(-10deg);
	transform: skewY(-10deg);
	overflow: hidden;
	z-index: -1;
}
.headerBackground::before,
.headerBackground::after {
	display: block;
	position: absolute;
	content: "";
	width: 80%;
	height: 33.333333333333336%;
	opacity: 0.3;
	-webkit-filter: blur(15px);
	filter: blur(15px);
}
.headerBackground::before {
	background: var(--color-1);
	right: 0;
}
.headerBackground::after {
	background: var(--color-2);
	bottom: 0;
}

.cosmeticHeader {
	width: 100%;
	height: 60vh;
	object-fit: cover;
	opacity: 1;
}
.headerContent {
	text-align: center;
	padding: 4rem 4rem;
	margin: 0 auto;
}

.prompModal {
	min-width: 90vw;
	height: fit-content;
}

.prompImage {
	width: 100%;
	height: 100%;
}

@media (min-width: 58rem) {
	.headerContent {
		text-align: left;
		padding: 10rem 4rem;
		padding-bottom: 0;
		max-width: 54rem;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
	}
}
.headerHero h1,
.headerHero p {
	color: #fff;
	text-shadow: 0 0.5rem 1rem rgba(50, 0, 100, 0.1);
}
.headerHero h1 {
	margin: 0;
	font-size: 3rem;
}
.headerHerop {
	font-size: 1.5rem;
	margin-bottom: 2rem;
}

.headerHero hr {
	margin: 2rem 0;
	width: 60%;
}
.headerVisuals {
	position: relative;
	margin-top: 5rem;
	-webkit-transform: rotate(-10deg);
	transform: rotate(-10deg);
}
@media (min-width: 58rem) {
	.headerVisuals {
		margin: 0;
		-webkit-transform: translateY(-5rem) rotate(-10deg);
		transform: translateY(-5rem) rotate(-10deg);
	}
}
.button {
	padding: 1.5rem 2rem;
	border: 0;
	color: var(--color-3);
	font-size: 1.2rem;
	font-weight: bold;
	background: #fff;
	border-radius: 3px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	cursor: pointer;
	box-shadow: 0 1.75rem 2rem -0.4rem rgba(50, 0, 100, 0.15);
}
.button:hover {
	color: var(--color-4);
	box-shadow: 0 1.75rem 3rem 0rem rgba(50, 0, 100, 0.1);
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}
.button:active {
	box-shadow: 0 1.75rem 2.5rem -0.2rem rgba(50, 0, 100, 0.125);
	-webkit-transform: scale(1.025);
	transform: scale(1.025);
}

.headerImage {
	display: inline-block;
	width: 50rem;

	opacity: 1;
	overflow: hidden;
}

.industry {
	max-width: 30rem;
	margin: 0 10rem;
}

.special {
	max-width: 35rem;
}

.paragraph {
	/* width: 70%; */
	margin: auto;
	padding: 0;
	font-size: 1.25rem;
	line-height: 1.7;
	text-align: center;
	font-weight: 300;
}

.cardsGrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 2rem;
	padding: 5rem;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.videoModal > div {
	width: 50vw;
}
@media screen and (max-width: 414px) {
	.container {
		max-width: 100vw;
		justify-content: space-around;
	}
	.react-player {
		width: 80vw;
	}
	.Modal {
		width: 40vw;
	}
	.ProductHeader {
		margin: 0 1vw;
		width: 10rem;
		height: 10rem;
	}
	.headerImage {
		width: 40vw;
		height: auto;
	}
	.cardsGrid {
		display: grid;
		grid-template-columns: 1fr;
		padding: 1rem;
	}
	.paragraph {
		font-size: 1rem;
	}
}

@media screen and (min-width: 415px) and (max-width: 769px) {
	.container {
		max-width: 100%;
		justify-content: space-around;
	}
	.ProductHeader {
		margin: 0 1vw;
		width: 10rem;
		height: 10rem;
	}
	.Modal {
		width: 40vw;
	}
	.headerImage {
		width: 60vw;
		height: auto;
	}
	.cardsGrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 2rem;
	}
}

@media screen and (max-width: 600px) {
	.videoModal > div {
		width: 90vw;
	}
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
	.videoModal > div {
		width: 70vw;
	}
}
