.container {
	display: flex;
	flex-direction: column;
	max-width: 15rem;
	/* text-align: center; */
	justify-content: space-evenly;
	height: 25rem;
}

.imageContainer {
	position: relative;
	border: 1px solid #ddd;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 5.5rem;
	overflow: hidden;
}

.ribbon {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 0.7rem;
	background: red;
	color: white;
	text-shadow: 1px 1px 1px black;
	padding: 0.3rem;
	/* transform: rotateZ(40deg) translateX(45px) translateY(-10px); */
	width: 10rem;
	text-align: center;
	animation: badge 2s ease-out forwards;
}
.imageContainer .gunImage {
	width: 100px;
}
.imageContainer img {
	height: 10rem;
	width: 3rem;
	opacity: 1;
}

.container h5 {
	margin: 1rem 0;
	padding: 0;
	font-size: 1.3rem;
}

.container p {
	margin: 0.1rem 0;
	padding: 0;
	font-size: 0.8rem;
}

.container button {
	margin-top: 2rem;
}

.imageContainer img.containersFeatured{
	width: 150px;
}

.imageContainer img.whipesFeatured{
	width: 200px;
}

@keyframes badge {
	0% {
		transform: rotateZ(40deg) translateX(100px) translateY(100px) scale(3);
	}
	100% {
		transform: rotateZ(40deg) translateX(45px) translateY(-10px) scale(1);
	}
}

@media screen and (max-width: 376px) {
	.container {
		width: 80vw;
	}
}
