.containerSection {
  display: flex;
  justify-content: space-around;
  background: #eff1e4;
  height: 30rem;
  padding: 1rem 0;
  align-items: center;
}

.textContainer {
  /* width: 50%;
  padding: 3rem; */
}

.textHeadlineContainer {
  display: flex;
  justify-content: center;
}
.textHeadline {
  /* position: relative; */
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
  background: rgb(114, 109, 109);
  width: 30rem;
  font-size: 1.5rem;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.circle {
  /* position: absolute; */
  left: 0;
  background: #eff1e4;
  border-radius: 50% 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle img {
  opacity: 1;
  width: 70%;
  vertical-align: middle;
}

.textHeadline h1 {
  padding: 0.5rem 1rem;
}
.text {
  letter-spacing: 2px;
  line-height: 1.5;
  font-size: 1.1rem;
}

.text li {
  margin: 0.3rem;
}

.text .applicationListItem:first-of-type {
  color: green;
  list-style: none;
  margin-bottom: 1rem;
}

.image {
  width: 30%;
  height: 90%;
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  opacity: 1;
}

.image {
  width: 30%;
  height: 90%;
  width: 23rem;
  height: 23rem;
  border-radius: 50%;
  opacity: 1;
}

table {
  text-align: center;
  border-spacing: 2rem;
}

th {
  font-size: 1.5rem;
}

td {
  font-size: 1rem;
}

tr {
  margin: 2rem;
}

@media screen and (max-width: 414px) {
  .textHeadline h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 320px) {
  .image {
    width: 17rem;
    height: 17rem;
  }
}
