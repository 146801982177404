.container {
  font-family: "Poppins", sans-serif;
  margin: 10rem;
}

.contact {
  background-image: url("../../images/contact-us.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (max-width:575px) {
  .contactCard {
    /* height: 90vh; */
       transform:scale(1, 0.75);
   }
 }

.contactContainer{
  opacity: 0.9;
}


.contactSection h1 {
  position: absolute;
  top: 0;
  color: #fff;
}

.contactSection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  /* background: linear-gradient(
    to right,
    var(--bs-gray) 0%,
    var(--bs-dark) 50%,
    var(--bs-gray) 100%
  );
 */
  margin: 5px 0;
  margin-bottom: 3rem;
}

.contactSection .container {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
  z-index: 100;
}

.contactSection .container .contactInfo {
  position: absolute;
  top: 40px;
  width: 350px;
  height: calc(100% - 80px);
  /* background: #0f3959; */
  background: var(--bs-dark);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgb(0, 0, 0, 0);
  font-size: 24px;
}

.contactSection .container .contactInfo h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.contactSection .container .contactInfo .info {
  position: relative;
  margin: 20px 0;
}

.contactSection .container .contactInfo .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  align-items: flex-start;
}

.contactSection .container .contactInfo .info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
}

.icon {
  color: white;
  opacity: 0.5;
}

.contactSection .container .contactInfo .info li span:nth-child(2) {
  color: #fff;
  margin-left: 10px;
  font-weight: 300;
  opacity: 0.5;
  font-size: 18px;
}

.logo {
  width: 150px;
  align-self: center;
  opacity: 0.8;
}

.logo:hover {
  opacity: 1;
}

li:hover .icon,
.contactSection .container .contactInfo .info li:hover span:nth-child(2) {
  opacity: 1;
}

.contactSection .container .contactForm {
  position: absolute;
  padding: 70px 50px;
  background: #fff;
  width: calc(100% - 150px);
  font-size: 24px;
  margin-left: 150px;
  padding-left: 250px;
  height: 100%;
  background: lightgrey;
  /* background: radial-gradient(var(--bs-dark), transparent); */

  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
}

.contactSection .container .contactForm h2 {
  color: #0f3959;
  font-size: 24px;
  font-weight: 500;
}

.contactSection .container .contactForm .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

.contactSection .container .contactForm .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}

.contactSection .container .contactForm .formBox .inputBox.w50 {
  width: 47%;
}

.contactSection .container .contactForm .formBox .inputBox.w100 {
  width: 100%;
}

.contactSection .container .contactForm .formBox .inputBox input,
.contactSection .container .contactForm .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  border-bottom: 1px solid #777;
  outline: none;
}

.contactSection .container .contactForm .formBox .inputBox textarea {
  min-height: 120px;
}

.contactSection .container .contactForm .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  transition: 0.5s;
  pointer-events: none;
}

.contactSection .container .contactForm .formBox .inputBox input:focus ~ span,
.contactSection
  .container
  .contactForm
  .formBox
  .inputBox
  textarea:focus
  ~ span,
.contactSection .container .contactForm .formBox .inputBox input:valid ~ span,
.contactSection
  .container
  .contactForm
  .formBox
  .inputBox
  textarea:valid
  ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--bs-light);
}

.contactSection
  .container
  .contactForm
  .formBox
  .inputBox
  input[type="submit"] {
  position: relative;
  cursor: pointer;
  background: #00273f;
  border-radius: 5px 5px;
  color: #eff1e4;
  border: none;
  max-width: 150px;
  padding: 12px;
}

.contactSection
  .container
  .contactForm
  .formBox
  .inputBox
  input[type="submit"]:hover {
  background: white;
  border: 1px solid #00273f;
  color: #00273f;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 6px 6px 0 rgba(0, 0, 0, 0.19);
}


.contact{
  width: 100%;
  height: 100vh;
}

.head{
  background-color: rgb(83, 212, 133);
  border-radius: 5px;
  margin-top: -40px;
  -webkit-box-shadow: -1px 9px 27px -8px rgba(83,212,133,1);
  -moz-box-shadow: -1px 9px 27px -8px rgba(83,212,133,1);
  box-shadow: -1px 9px 27px -8px rgba(83,212,133,1);
}

.effect1{
  border: 0;
  outline: none;
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
}

.effect1 ~ .focusBorder1{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: rgb(83, 212, 133);
  transition: 0.4s;
}

.effect1:focus ~ .focusBorder1{
  width: 100%;
  transition:  0.4;
  left: 0;
}

@media (max-width:575px) {
 .recaptcha {
      transform:scale(0.77);
      transform-origin:0 0;
      margin-bottom: -20px;
  }
}

@media (max-width:350px) {
  .recaptcha {
       transform:scale(0.67);
       transform-origin:0 0;
       margin-bottom: -20px;
   }
 }

.btn1{
  background: rgb(83, 212, 133);
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 145px;
}

.btn1:hover{
  background:rgba(255, 255, 255, 0);
  color: rgb(83, 212, 133);
  border: 1px solid rgb(83, 212, 133);
}

.disabledButton{
  cursor: not-allowed;
}

.disabledButton:hover{
  background: rgb(83, 212, 133);
  color: white;
  border: none;
}
