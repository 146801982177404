/* Card */

.wrapper {
  width: auto;
  height: 400px;
  background: white;
  /* margin: 0 5rem; */
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
}

.wrapper .container {
  width: 100%;
  height: 100%;
}
.wrapper .container .top {
  height: 70%;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .wrapper .container .bottom {
  width: 200%;
  height: 30%;
} */
.wrapper .container .bottom.clicked {
  transform: translateX(-50%);
}
.wrapper .container .bottom h1 {
  margin: 0;
  padding: 0;
}
.wrapper .container .bottom p {
  margin: 0;
  margin-top: 0.1rem;
  margin-bottom: 0.5rem;
  /* margin: 0.5rem 0; */
  padding: 0;
}
.wrapper .container .bottom .left {
  height: 100%;
  background: #f4f4f4;
}
.wrapper .container .bottom .left .details {
  padding: 20px;
  margin: 2px;
}
