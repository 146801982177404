.details {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #b5f8bb 100%);

	/* height: calc(100vh - 40px); */
	margin-bottom: 1rem;
}

.container {
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
	max-width: 1200px;
}
.titletop {
	position: relative;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	padding-bottom: 5px;
	margin-bottom: 20px;
	color: #212529;
	margin-top: 0;
}
.title {
	font-size: 45px;
	line-height: 54px;
	margin-bottom: 10px;
	font-weight: 700;
	max-width: 620px;
	color: #212529;
	padding-right: 50px;
}
.text {
	font-weight: 300;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 60px;
	color: #212529;
	padding-right: 30px;
	margin-top: 0;
}

:root {
	--color-1: #21d4fd;
	--color-2: #b721ff;
	--color-3: #08aeea;
	--color-4: #2af598;
}

.header {
	position: relative;
	height: 80vh;
	display: block;
	max-width: 100vw;
	overflow: hidden;
}

.headerBackground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(19deg, var(--color-1), var(--color-2));
	-webkit-transform-origin: 0px 0px;
	transform-origin: 0px 0px;
	-webkit-transform: skewY(-10deg);
	transform: skewY(-10deg);
	overflow: hidden;
	z-index: -1;
	max-width: 100vw;
	overflow: hidden;
}
.headerBackground::before,
.headerBackground::after {
	display: block;
	position: absolute;
	content: "";
	width: 80%;
	height: 33.333333333333336%;
	opacity: 0.3;
	-webkit-filter: blur(15px);
	filter: blur(15px);
}
.headerBackground::before {
	background: var(--color-1);
	right: 0;
}
.headerBackground::after {
	background: var(--color-2);
	bottom: 0;
}

.cosmeticHeader {
	width: 100%;
	height: 60vh;
	object-fit: cover;
	opacity: 1;
}
.headerContent {
	text-align: center;
	padding: 4rem 0rem;
	margin: 0 auto;
}
.headerHero {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}
.headerHero h1,
.headerHero p {
	color: #fff;
	text-shadow: 0 0.5rem 1rem rgba(50, 0, 100, 0.1);
	letter-spacing: 3px;
}
.headerHero h1 {
	margin: 0;
	font-size: 3rem;
}
.headerHero p {
	font-size: 1.5rem;
	margin-bottom: 2rem;
}

hr {
	margin: 2rem 0;
	width: 60%;
}
.headerVisuals {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
}

.button {
	padding: 1.5rem 2rem;
	border: 0;
	color: var(--color-3);
	font-size: 1.2rem;
	font-weight: bold;
	background: #fff;
	border-radius: 3px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	cursor: pointer;
	box-shadow: 0 1.75rem 2rem -0.4rem rgba(50, 0, 100, 0.15);
}
.button:hover {
	color: var(--color-4);
	box-shadow: 0 1.75rem 3rem 0rem rgba(50, 0, 100, 0.1);
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}
.button:active {
	box-shadow: 0 1.75rem 2.5rem -0.2rem rgba(50, 0, 100, 0.125);
	-webkit-transform: scale(1.025);
	transform: scale(1.025);
}

.headerImage {
	display: inline-block;
	width: 100%;
	opacity: 1;
	overflow: hidden;
}

.headerVisuals .cosmeticImageHeader {
	opacity: 1;
	height: 18rem;
	width: 5.5rem;
	filter: drop-shadow(8px 8px 10px black);
}

.industry {
	/* max-width: 30rem;
  margin: 0 10rem; */
}
/* 
.special {
	max-width: 35rem;
} */

.stampContainer {
	display: flex;
	justify-content: flex-start;
}

.stampImage {
	/* margin-left: 95%; */
	height: 130px;
}
.stampImage2 {
	/* margin-left: 95%; */
	height: 130px;
}

.bioStamps {
	position: fixed;
	/* bottom: 0; */
	right: 0;
	/* margin-right: 10rem; */
	/* width: 18rem; */
	/* height: 13rem; */
	opacity: 1;
	display: flex;
	flex-direction: column;
	z-index: 999;
}

.tooltip {
	width: 15rem;
	height: 7rem;
	font-size: 1.5rem;
	text-transform: uppercase;
	text-shadow: 1px 1px black;
	line-height: 2;
}

@media screen and (max-width: 320px) {
	.header {
		height: 92vh;
	}
}
