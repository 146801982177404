.textHeadlineContainer {
  display: flex;
  justify-content: center;
}

.textHeadline {
  position: relative;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 1px 1px black;
  letter-spacing: 2px;
  background: rgb(114, 109, 109);
  width: 30rem;
  font-size: 1.5rem;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.circle {
  left: 0;
  background: #eff1e4;
  border-radius: 50% 50%;
  width: 60px;
  height: 60px;
  transform: translateX(-0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle img {
  opacity: 1;
  width: 70%;
  vertical-align: middle;
}

.textHeadline h1 {
  margin: 0;
  padding: 0;
}

.textPacking {
  height: 4rem;
  position: relative;
}

.badge {
  position: relative;
  width: 216px;
  display: inline-block;
  margin: 1rem 5rem;
}
.badge .title {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.7rem;
  position: absolute;
  top: 0;
  border-radius: 8px 8px 0 0;
  text-align: center;
  width: 100%;
  background: red;
  padding: 20px 0;
}
.badge .subtitle {
  position: absolute;
  font-family: "Fjalla One", sans-serif;
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
}
.badge .subtitle:after {
  content: "★ ★ ★";
  display: block;
  font-size: 0.4em;
  position: relative;
  margin: 15px 0 0;
  transition: all 0.3s;
}
.badge:hover .subtitle:after {
  word-spacing: 30px;
}

.red .title {
  background: #dc514e;
}
.red .subtitle:after {
  color: #dc514e;
}

.green .title {
  background: #2ecc71;
}
.green .subtitle:after {
  color: #2ecc71;
}

.gray .title {
  background: #95a5a6;
}
.gray .subtitle:after {
  color: #95a5a6;
}

.blue .title {
  background: #3498db;
}
.blue .subtitle:after {
  color: #3498db;
}
