.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: #eff1e4;
  color: #283618;
  display: flex;
  justify-content: space-evenly;
  font-size: 1rem;
  z-index: 500;
}
.logo {
  width: 7rem;
  height: 7rem;
}
.mainNavContactInfoItems {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  font-size: 0.7rem;
}
.mainNav {
  display: flex;
  flex-wrap: wrap;
}
.navItemsContainer {
  display: flex;
  font-size: 1rem;
  color: #3a3737;
  margin: 0.4rem;
  list-style: none;
  align-items: flex-end;
  padding: 0;
  text-decoration: none;
  margin-top: auto;
  align-self: end;
  justify-content: center;
  width: 100%;
}
.imgChemical {
  width: 3rem;
  height: 3rem;
  align-self: flex-end;
  margin-bottom: 0.5rem;
}

.imgChemical:hover {
  animation: push-up 1s ease-out forwards;
}
.navItems {
  margin: 0.4rem;
  list-style: none;
  display: flex;
  align-items: flex-end;
  padding: 0;
  text-decoration: none;
  color: inherit;
}

.navItem {
  margin: 1rem;
}

.navItem a {
  text-decoration: none;
  color: inherit;
}

.navItemsContainer ul li a:hover {
  border-bottom: 4px solid #00273f;
  color: #00273f;
}

.navItemsContainer ul li a.active {
  border-bottom: 4px solid #00273f;
  color: #00273f;
}
@keyframes push-up {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-1.5rem);
  }
  100% {
    transform: translateY(-1rem);
  }
}