/* Global style overrides for contact-us page*/
.row {
	margin-left: 0;
	margin-right: 0;
}
/* End of global style overrides for contact us page */

.fluid-container {
	/* background-color: #14212d; */
	/* background-color: #06C972; */
	background-color: var(--bs-green);
	height: 100%;
}

@media (max-width:575px) {
	.recaptcha {
		 transform:scale(0.77);
		 transform-origin:0 0;
		 margin-bottom: -20px;
	 }
   }
   
.heading {
	text-align: left;
	margin-top: 30px;
	font-size: 20px;
	color: white;
}
.form-group {
	width: 100%;
	margin: auto;
	padding-bottom: 20px;
}
.note {
	color: #fff;
	/* margin-left: 85px;
      margin-top: -30px;   */
}
.terms {
	color: #fff;
}
/* img {
    border-radius: 50%;
    height: 80px;
  } */
.details {
	color: #fff;
}

/* .manager {
    margin-top: -120px;
  } */
.heading-social {
	color: white;
}
.social-icons img {
	width: 50px;
	height: 50px;
}

@media (max-width: 767px) {
	.manager {
		margin-top: 0;
	}
}
