.mainContainer {
	width: 100vw;
	overflow: hidden;
	padding: 0 1vw;
}
.carouselContainer {
	position: relative;
	width: 100%;
	/* padding: 2rem; */
}

.slide-img {
	height: 75vh;
	object-fit: cover;
	filter: brightness(65%);
}

.headlineContainer {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	transform: translate(0%, 0%);
	text-align: center;
	/* margin-bottom: 6.5rem; */
	text-transform: uppercase;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.headlineContainer img {
	opacity: 1;
	position: relative;
	width: 12rem;
	height: 12rem;
	margin: 5rem;
	/* right: 10rem; */
}

.headlineContainer h1 {
	/* font-size: 1.4rem; */
	border-radius: 30px;
	padding: 2rem;
	/* background: rgba(0, 0, 0, 0.75); */
	color: white;
	text-shadow: 1px 1px black;
	letter-spacing: 2px;
}

.button {
	padding: 1rem 2rem;
	border: 0;
	color: var(--color-3);
	font-size: 1.2rem;
	font-weight: bold;
	background: #fff;
	border-radius: 3px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	cursor: pointer;
	box-shadow: 0 1.75rem 2rem -0.4rem rgba(50, 0, 100, 0.15);
}
.button:hover {
	color: var(--color-4);
	box-shadow: 0 1.75rem 3rem 0rem rgba(50, 0, 100, 0.1);
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}
.button:active {
	box-shadow: 0 1.75rem 2.5rem -0.2rem rgba(50, 0, 100, 0.125);
	-webkit-transform: scale(1.025);
	transform: scale(1.025);
}

.caroselHeadline {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vl {
	border-left: 6px solid white;
	height: 210px;
}

.headlineContainer h1 p {
	margin: 0.5rem;
}

section {
	height: fit-content;
}

.secondaryHeadline {
	text-align: center;
	color: #283618;
	text-transform: uppercase;
	margin-top: 5rem;
	letter-spacing: 2px;
}

h5:nth-of-type(2) {
	margin: 0;
}

.featuredProductsConatiner {
	/* margin: 5rem 5rem; */
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
}

/* .strokeme {
	color: #40a448;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  } */

.featuredHeadline {
	margin: 2rem 0;
	text-transform: uppercase;
	background: rgb(85, 81, 81);
	padding: 0.5rem 1.5rem;
	color: white;
	text-shadow: 1px 1px 1px black;
	height: 3rem;
	/* border-radius: 100px 100px; */
}

.projectsHeadline {
	margin: 2rem 10rem;
	margin-top: 6rem;
}

.featuredProductsFlex {
	display: flex;
	list-style: none;
	justify-content: space-evenly;
	margin: 1vw;
	padding: 0;
}

.ecoImgsItems {
	list-style: none;
	display: flex;
	justify-content: center;
	text-align: center;
	margin: 5rem;
	margin-bottom: 0;
}

.ecoImgsItems li img {
	opacity: 1;
	width: 33%;
}

.ecoImgsItemGroup {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	align-items: center;
	color: #283618;
}

.ecoImgsItem {
	width: 80%;
}

.tvContainer {
	display: flex;
	background: #eff1e4;
	padding: 1rem;
	font-size: 1.2rem;
	align-items: center;
}

.specializationText {
	color: #283618;
	flex-direction: column;
	width: 50%;
	padding: 2rem;
	line-height: 2rem;
}

.specializationText h1 {
	margin-bottom: 0.5rem;
	text-shadow: 2px 2px 1px #ccc;
}

.specializationText h5 {
	text-shadow: 2px 2px 3px #ccc;
	margin: 0;
}

.picturesGridContainer {
	margin: 3rem;
	padding: 2rem;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(8, 5vw);
	grid-gap: 15px;
}

.picturesGridContainer img {
	width: 100%;
	height: 100%;
}

.gridItem1 {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 3;
}

.gridItem2 {
	grid-column-start: 3;
	grid-column-end: 5;
	grid-row-start: 1;
	grid-row-end: 3;

	/** Alternative Syntax **/
	/* grid-column: 3 / span 2;  */
	/* grid-row: 1 / span 2; */
}

.gridItem3 {
	grid-column-start: 5;
	grid-column-end: 9;
	grid-row-start: 1;
	grid-row-end: 6;

	/** Alternative Syntax **/
	/* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gridItem4 {
	grid-column-start: 1;
	grid-column-end: 5;
	grid-row-start: 3;
	grid-row-end: 6;

	/** Alternative Syntax **/
	/* grid-column: 1 / span 4;  */
	/* grid-row: 3 / span 3; */
}

.gridItem5 {
	grid-column-start: 1;
	grid-column-end: 5;
	grid-row-start: 6;
	grid-row-end: 9;

	/** Alternative Syntax **/
	/* grid-column: 1 / span 4; */
	/* grid-row: 6 / span 3; */
}

.gridItem6 {
	grid-column-start: 5;
	grid-column-end: 9;
	grid-row-start: 6;
	grid-row-end: 9;

	/** Alternative Syntax **/
	/* grid-column: 5 / span 4; */
	/* grid-row: 6 / span 3; */
}
.industry-card {
	height: 30vh;
	width: 100%;
	transition: all 2s ease;
	overflow: hidden;
}

.industry-card:hover {
	cursor: pointer;
}

.industry-card:hover .card-bg {
	opacity: 1;
}

.card-bg {
	width: 100%;
	height: 100%;
	opacity: 0.2;
	object-fit: cover;
	transition: all 2s ease;
}
.aboutContainer {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 1rem;
}
.aboutImg_landing {
	width: 100%;
}
.imgContainer img {
	margin-top: 2rem;
	width: 36.6vw;
	height: 64vh;
}
/* .aboutParagraphContainer {
  font-size: 1rem;
  flex-basis: auto;
  width: 50%;
  line-height: 1.5rem;
  align-self: flex-end;
} */

@media screen and (max-width: 414px) {
	.caroselHeadline {
		max-width: 100vw;
		justify-content: space-around;
	}
	.headlineContainer img {
		margin: 0 1vw;
		width: 10rem;
		height: 10rem;
	}
	.ecoImgsItems {
		margin: 0;
		margin-top: 2rem;
	}
	.headlineContainer h1 {
		padding: 0;
	}
	.featuredHeadline.projectsHeadline {
		margin: 2rem;
	}

	.featuredProductsConatiner {
		width: 100%;
		margin: 0;
		padding: 0 2rem;
	}

	.player-wrapper.vp-center{
		align-items: none !important;
	}

	.player-wrapper {
		position: relative;
		padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
	  }

	.react-player {
		position: absolute;
		top: 0;
		left: 0;
		align-items: none !important;
		max-height: 10rem;
		/* width: 80vw; */
	}
	.industry-card {
		width: 100%;
		margin: 0.5rem 0 !important;
	}
	.picturesGridContainer {
		margin: 0;
	}
}

@media screen and (min-width: 415px) and (max-width: 769px) {
	.caroselHeadline {
		max-width: 100%;
		justify-content: space-around;
	}
	.headlineContainer img {
		margin: 0 1vw;
		width: 10rem;
		height: 10rem;
	}
	.ecoImgsItems {
		margin: 5rem 2rem;
	}
	.featuredProductsConatiner {
		padding: 2rem 5rem;
	}
	.featuredHeadline.projectsHeadline {
		margin: 5rem;
	}
	.industry-card {
		width: 100%;
		margin: 0.5rem 0 !important;
	}
}
