/* .footer {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .footerContainer {
    display: flex;
    position: absolute;
    bottom: 0;
    color: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-evenly;
  }
  
  .footerContainerCopyright {
    padding: 1rem;
  }
  
  .footerContainerHeadline {
    margin: 1rem 0;
    padding: 0;
  }
  
  .footerContainerSubHeadline {
    margin: 0;
    font-size: 0.8rem;
  }
  
  .footerContainerSubHeadlineCopyright {
    margin: 0;
    padding: 0;
    font-size: 0.5rem;
  }
  
  .footerContainerItems {
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
    color: white;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footerContainerItem {
    margin: 1rem;
  }
  
  .footerContainerItem .footerContainerItemContent {
    margin: 0.5rem;
  }
  
  .footerContainerItem .footerContainerItemContent a {
    text-decoration: none;
    color: inherit;
  }
  
  .footerContainerItem .footerContainerItemContent a:hover {
    text-decoration: underline;
  }
  
  @media (max-height: 768) {
    .footer {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
    .footerContainer{
      height: auto;
    }

    .footerContainerItem .footerContainerItemContent {
      text-size-adjust: 10px;
    }
  } */

.footer {
	/* background-color: #00273F; */
	/* background-color: #06C972; */
	background-color: var(--bs-green);
}
.text {
	font-size: medium;
}
a {
	text-decoration: none;
}
