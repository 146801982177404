.product h3 {
	font-size: 0.7em;
	letter-spacing: 1.2px;
	color: #a6a6a6;
}

.product img {
	max-width: 100%;
	-webkit-filter: drop-shadow(1px 1px 3px #a6a6a6);
	filter: drop-shadow(1px 1px 3px #a6a6a6);
}

/* ----- Product Section ----- */
.product {
	position: relative;
	display: grid;
	grid-template-columns: 0.9fr 1fr;
	margin: auto;
	padding: 1em 0;
	background-color: #eff1e4;
	border-radius: 5px;
	/* width: 100px; */
	min-height: 350px;
	font-size: 1rem;
	overflow: hidden;
}

/* ----- Photo Section ----- */
.productPhoto {
	position: relative;
	margin-right: 1rem;
}

.photoContainer {
	position: relative;

	/* left: -2.5em; */
	display: grid;
	grid-template-rows: 1fr;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	box-shadow: 4px 4px 25px -2px rgba(0, 0, 0, 0.3);
	overflow: float-clearing;
}

.photoMain {
	display: flex;
	border-radius: 6px 6px 0 0;
	background-color: #00273f;
	background: radial-gradient(var(--color-1), #00273f);
	padding: 1rem;
	height: 100%;
}

.photoMain img.cosmetic {
	width: 40%;
	margin: auto;
	opacity: 1;
}

.photoMain img {
	width: 100%;
	-webkit-filter: saturate(150%) contrast(120%) hue-rotate(10deg)
		drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3));
	filter: saturate(150%) contrast(120%) hue-rotate(10deg)
		drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3));
}

/* ----- Informations Section ----- */
.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* padding: 1rem; */
}

.title h1 {
	margin-bottom: 0.1em;
	color: #4c4c4c;
	font-size: 1.5em;
	font-weight: 900;
}
.title span {
	font-size: 0.7em;
	color: #a6a6a6;
}

.variant {
	margin-bottom: 1rem;
	overflow: auto;
}
.variant h3 {
	margin-bottom: 1.1em;
}
.variant li {
	float: left;
	width: 35px;
	height: 35px;
	/* padding: 3px; */
	border: 1px solid transparent;
	border-radius: 3px;
	cursor: pointer;
}
.variant li:first-child,
.variant li:hover {
	border: 1px solid #a6a6a6;
}
.variant li:not(:first-child) {
	margin-left: 0.1em;
}

.ribbon {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 0.75rem;
	background: red;
	color: white;
	text-shadow: 1px 1px 1px black;
	padding: 0.3rem;
	transform: rotateZ(40deg) translateX(45px) translateY(-10px);
	width: 10rem;
	text-align: center;
}

.desc {
	line-height: 1.5;
}

.cardPhoto {
	height: 300px;
	object-fit: contain;
}

@media screen and (max-width: 415px) {
	.product {
		min-width: 300px;
	}
}
