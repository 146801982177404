.banner {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

.title {
  position: absolute;
  top: 50%;
  left: 20%;
  right: 20%;
  background-color: rgba(0, 0, 0, 0.5);
}
.images {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.btn-industry{
  position: absolute;
  bottom: 0;
  top: 40px;
}
