.specialization-card {
  height: 50vh;
  transition: all 2s ease;
}

.specialization-card:hover {
  cursor: pointer;
}
.specialization-card:hover {
  transform: scale(1.1);
}
.specialization-card > .card-img-overlay:hover > .specialization-products-btn {
  display: block;
  animation: fadeIn 2s ease 1 forwards;
}

.specialization-products-btn {
  color: var(--bs-white);
  background-color: var(--bs-dark);
  border-color: var(--bs-white);
  display: none;
}
.specialization-products-btn:hover,
.specialization-products-btn:active,
.specialization-products-btn:focus {
  color: var(--bs-dark);
  background-color: var(--bs-white);
  border-color: var(--bs-dark);
}

.specialization-products-btn:active,
.specialization-products-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5) !important;
}

.truncate-overflow {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 0;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 786px) {
  .truncate-overflow {
    -webkit-line-clamp: 7;
  }
  .specialization-products-btn {
    display: block;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
